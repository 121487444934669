<template>
  <div style="background-color: #21262a; font-size:110%" v-if="identite">
    <div class=" d-flex flex-column justify-content-center align-items-center identite w-100">
      <div class="ml-4 m-2">
      <div>
        <span style="color: var(--indigo)">public class </span>
        <span style="color: #2aa77a">{{identite.class}} </span>
        <span style="color: var(--yellow)"> { </span>
      </div>
      <div class="ml-3">
        <div>
          <span style="color: var(--indigo)">public string </span>
          <span style="color: var(--info)">{{identite.nom}} </span>
          <span style="color: white"> => </span>
          <span style="color: var(--orange)"> "BEAUD" </span>
        </div>
        <div>
          <span style="color: var(--indigo)">public string </span>
          <span style="color: var(--info)">{{identite.prenom}} </span>
          <span style="color: white"> => </span>
          <span style="color: var(--orange)"> "Thomas" </span>
        </div>
        <div>
          <span style="color: var(--indigo)">public int </span>
          <span style="color: var(--info)">Age </span>
          <span style="color: white">=> </span>
          <span style="color: #2ed19866"> {{age}} </span>
        </div>
      </div>
      <div>
        <span style="color: var(--yellow)">} </span>
      </div>
      </div>
    </div>
  </div>
</template>

<script src="./identite.js"></script>

<style scoped>
.identite{

}
</style>
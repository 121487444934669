import communLangage from '@/commun/commun.langage';


export default {
     name: 'ParcoursProfessionel',

     data() {
          return {
               
               entreprises:[],
               titres:{},
          }
     },

     computed: {
          langage() {
               return communLangage.langage;
          },
          parcoursPro(){
               return communLangage.parcoursPro;
          }
     },

     methods: {
         
     },

     created() {
     }
}
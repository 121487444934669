import Identite from "@/components/identite/identite.vue";
import communLangage from "@/commun/commun.langage";
import AOS from "aos";

import fichier from "@/utils/fichier.js";

export default {
  name: "InformationsGenerales",

  components: {
    Identite,
  },

  data() {
    return {
          pdf: null,
    };
  },

  computed: {
    langage() {
      return communLangage.langage;
    },
    infosGenerales(){
         return communLangage.infosGenerales;
    }
  },

  methods: {
    recupererCV() {
      fichier.recupererfichierCV(`Thomas_BEAUD_CV`).then((result) => {
        this.pdf = result.data;
      });
    },
  },

  created() {
    this.recupererCV();
    AOS.init();
  },
};

<template>
  <div v-if="formation">
    <div class="titre-height pt-2">
      <h1 class="p-3 m-0 text-center" data-aos="zoom-in" data-aos-duration="1500">
        {{ formation.titres.section }}
      </h1>
      <hr class="border-info border-2 w-75 mt-0 mt-2" />
    </div>
    <table class="m-3" style="font-size: 130%" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
      <tbody v-for="(ecole, index) in formation.ecoles" :key="index">
        <tr
          class="d-flex d-flex-tel justify-content-center align-items-center"
          :class="{
            'mode-tel-flex': afficherPlus || index == 0,
            'mode-ordi-flex': !afficherPlus,
            ' mode-tel-mt-7': index != 0,
          }"
        >
          <td style="flex-basis: 15%" class="d-flex justify-content-center">
            <img :src="require(`@/assets/logo/${ecole.img}`)" :alt="`Logo ${ecole.code}`" :titre="`Logo ${ecole.code}`" class="w-100 mode-tel-width-90" />
          </td>

          <td style="flex-basis: 20%; font-size: 120%" class="mode-tel-pt-2 text-center">
            <b>{{ ecole.annee }}</b>
          </td>
          <td style="flex-basis: 50%">
            <p class="m-0 mb-2 mode-tel-text-center mode-tel-pt-2" style="font-size: 120%">
              <b>{{ ecole.titre }}</b>
            </p>
            <i style="font-size: 80%" class="text-justify"
              ><p class="m-0 mt-1">
                <strong
                  ><u>{{ formation.titres.projet }}:</u></strong
                >
                <span v-for="(projet, indexProjet) in ecole.projets" :key="indexProjet">
                  <span class="fa fa-circle align-middle ml-2" style="font-size: 50%"></span>
                  {{ projet }}
                </span>
              </p>
              <p class="m-0 mt-2" v-if="ecole.competences != null">
                <strong
                  ><u>{{ formation.titres.competence }}:</u></strong
                >
                <span v-for="(competence, indexCompetence) in ecole.competences" :key="indexCompetence">
                  <span class="fa fa-circle align-middle ml-2" style="font-size: 50%"></span>
                  {{ competence }}
                </span>
              </p>
            </i>
          </td>
        </tr>
        <tr class="d-flex justify-content-center mode-ordi-flex" :class="index == 1 ? 'mt-4 mb-2' : 'mb-3'" v-if="formation.ecoles.length - 1 != index">
          <td style="flex-basis: 15%" class="d-flex justify-content-center">
            <div class="border"></div>
          </td>
          <td style="flex-basis: 20%"></td>
          <td style="flex-basis: 50%"></td>
        </tr>
      </tbody>
    </table>
    <a
      :href="afficherPlus ? '#formation-iut' : '#formation'"
      class="mode-tel-flex d-flex justify-content-center align-items-center mt-3 font-weight-normal"
      :class="afficherPlus ? 'text-danger' : 'text-info'"
      style="cursor: pointer"
      @click="
        () => {
          afficherPlus = !afficherPlus;
        }
      "
    >
      <span class="fa fa-plus mr-1"></span>
      {{ afficherPlus ? autreTexte.afficherMoins : autreTexte.afficherPlus }}
    </a>
  </div>
</template>

<script src="./formation.js"></script>

<style scoped>
.border {
  border: 2px solid !important;
  height: 50px;
}

@media (max-width: 600px) {
  .d-flex-tel {
    flex-direction: column;
    height: auto !important;
    line-height: normal !important;
    align-items: center;
  }
  .d-flex-tel:not(:first-of-type) {
    margin-top: 15%;
  }
  .d-flex-tel div {
    margin: 0% !important;
    text-align: center;
  }
  .d-flex-tel div:not(:first-of-type) {
    margin-top: 2% !important;
  }
  .d-flex-tel div img {
    width: 100% !important;
  }
}
</style>

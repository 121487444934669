import moment from 'moment'
import communLangage from '@/commun/commun.langage';

export default {
    name:"Identite",

    data(){
        return{
            age:null,
        }
    },

    computed: {
        langage() {
             return communLangage.langage;
        },
        identite(){
          return communLangage.infosGenerales.identite
        }
   },

   methods: {
        
   },

    created(){
        moment.locale();

        this.age = moment().diff('2000-02-05', 'years');
    }
}

import InformationsGenerales from '@/views/CV/informationsGenerales.vue'
import Formation from '@/views/CV/formation.vue'
import ParcoursProfessionel from '@/views/CV/parcoursProfessionel.vue'
import ChevronProchainePage from '@/components/chevronProchainePage/chevronProchainePage.vue'


export default {
    name: "Accueil",
    el: '#app',
    components: {
        InformationsGenerales,
        Formation,
        ParcoursProfessionel,
        ChevronProchainePage
    },

    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },

        


    },

    created() {
        window.addEventListener("scroll", this.reveal);
    }

}
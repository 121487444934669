import communLangage from '@/commun/commun.langage.js';

export default {
     name: 'Formation',

     data() {
          return {
               afficherPlus: false,
               cpe: {},
               iut: {},
               lycee: {},

               ecoles:[],
               titres:{}
          }
     },

     computed: {
          langage() {
               return communLangage.langage;
          },
          formation(){
               return communLangage.formation;
          },
          autreTexte(){
               return communLangage.autreTexte;
          }
     },

     methods: {
          
     },

     created() {
     }
}